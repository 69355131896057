import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import {
  Tabs,
  Selector,
  Form,
  Input,
  Button,
  Popup,
  NavBar,
} from "antd-mobile";
import { DownOutline } from 'antd-mobile-icons'


const Collapse = ({title, valueGroupKey, children, arrow}) => {
  const [groupKey, selectGroupKey] = useState(null);

  return (
    <div className="page-list-collapse" key={valueGroupKey}>
      <div className="collapse-header">
        <span className="collapse-header-title">{title}</span>
        {
          arrow && (
            <div
              className={classNames("collapse-header-arrow", {
                "collapse-header-arrow-down": valueGroupKey == groupKey
              })}
              onClick={() => selectGroupKey(valueGroupKey == groupKey ? null : valueGroupKey)}>
              <span>{valueGroupKey == groupKey ? '收起' : '展开'}</span>
              <DownOutline />
            </div>
          )
        }
      </div>
      <div className={classNames("collapse-children",{
        'collapse-children-open': valueGroupKey == groupKey
      })}>
        {children}
      </div>

    </div>
  )
}

// 自定义类型
const MoreFilter = ({ filterData, onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  const [filterSubmitValue, setFilterSubmitValue] = useState({});

  const [popupVisible, setPopupVisible] = useState(false);

  const [popupValue, setPopupValue] = useState([]);

  const [popIndex, setPopIndex] = useState(0);

  const [filterRadio, setFilterRadio] = useState(null);



  /**选择筛选项 */
  const selectGroup = (index, v_index) => {
    setPopupValue(popValue => {
      let newData = popValue;
      newData[popIndex].children[index].list[v_index].flag = !popValue[popIndex].children[index].list[v_index].flag;
      return [...newData]
    })
  }


  /**筛选项组件 */
  const filterContent = useMemo(() => ({
    2: (value) => {
      const NOT_SHOW_TAB = 'pass_fair_show'      
      return value?.key !== NOT_SHOW_TAB && (
        <Collapse
          className="multiple-select-collapse"
          valueGroupKey={value?.groupKey}
          title={value?.groupName}
        >
          <Form.Item label="" name={value?.groupKey} className="multiple-select">
            <Selector
              multiple
              columns={3}
              options={value?.searchConditionRespDTOList?.map(v => {
                return {
                  label: v?.name,
                  value: v?.valueId
                }
              })}
              onChange={(_, extend) => {
                setFilterSubmitValue((data) => {
                  return {
                    ...data,
                    [value?.groupKey]: {
                      conditionList: extend?.items?.map(v => v?.value),
                      groupName:value?.groupName,
                      statConditionList: extend?.items?.map(v => v?.label),
                      displayType: value?.displayType,
                      selectType: value?.selectType
                    },
                  };
                });
              }}
            />
          </Form.Item>
        </Collapse>
      );
    },
    3: (value) => {
      const NOT_SHOW_TAB = 'pass_fair_show';
      return value?.key !== NOT_SHOW_TAB && (
        <Collapse
          className="multiple-select-collapse"
          valueGroupKey={value?.groupKey}
          title={value?.groupName}
          arrow={value?.searchConditionRespDTOList?.length > 9}
        >
          <Form.Item label="" name={value?.groupKey} className="multiple-select">
            <Selector
              multiple
              columns={3}
              options={value?.searchConditionRespDTOList?.map(v => {
                return {
                  ...v,
                  label: v?.name,
                  value: v?.valueId
                }
              })}
              onChange={(values, extend) => {
                setFilterSubmitValue((data) => {
                  return {
                    ...data,
                    [value?.groupKey]: {
                      conditionList: values,
                      groupName:value?.groupName,
                      statConditionList: extend?.items?.map(v => v?.name),
                      displayType: value?.displayType,
                      selectType: value?.selectType || 1
                    },
                  };
                });
              }}
            />
          </Form.Item>
        </Collapse>
      );
    },
    7: value => {
      return (
        <Collapse
        valueGroupKey={value?.groupKey}
          title={value?.groupName}
          // arrow={() =>
          //   filterSubmitValue?.[value.groupKey]?.statConditionList.join(",")
          // }
        >
          <Tabs>
            {
              value?.optionGroupList?.map(v => (
                <Tabs.Tab title={v?.name} key={v?.name}>
                    <Selector
                      multiple
                      columns={3}
                      options={v?.childList?.map((c) => {
                        return { ...c, label: c?.name, value: c?.valueId };
                      })}
                      onChange={(values, extend) => {
                        setFilterSubmitValue((data) => {
                          return {
                            ...data,
                            [value?.groupKey]: {
                              displayType: value?.displayType,
                              selectType: value?.selectType,
                              groupName:value?.groupName,
                              options:{
                                ...data[value?.groupKey]?.options,
                                [v?.name]:{
                                  conditionList: [...data[value.groupKey]?.conditionList || [], ...values],
                                  statConditionList: [...data[value.groupKey]?.statConditionList || [], ...extend?.items?.map(v => v?.name)],
                                },
                              },
                              isGroup: true
                            },
                          };
                        });
                      }}
                    />
                </Tabs.Tab>
              ))
            }
          </Tabs>
        </Collapse>
      )
    },
    0: (value) => {
      return (
        <Collapse
          className="range-input-with-select-collapse"
          valueGroupKey={value?.groupKey}
          title={value?.groupName}
        >
          <div className="range-input-with-select-content">
            <Form.Item
              className="range-input-with-select"
              label=""
              name={`${value?.groupKey}_min`}
            >
              <Input
                placeholder="最低"
                type="number"
                // min={value?.min}
                onChange={(v) => {
                  setFilterSubmitValue((data) => {
                    return {
                      ...data,
                      [value?.groupKey]: {
                        conditionList:[v, data?.[value.groupKey]?.conditionList?.[1]],
                        groupName:value?.groupName
                      },
                    };
                  });
                }}
              />
            </Form.Item>
            <span className="space-YA-0b"></span>
            <Form.Item
              className="range-input-with-select"
              label=""
              name={`${value?.groupKey}_max`}
            >
              <Input
                placeholder="最高"
                type="number"
                // max={value?.max}
                onChange={(v) => {
                  setFilterSubmitValue((data) => {
                    return {
                      ...data,
                      [value?.groupKey]: {
                        conditionList:[ data?.[value.groupKey]?.conditionList?.[0], v],
                        groupName:value?.groupName
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          {value?.searchConditionRespDTOList && (
            <Selector
              columns={3}
              value={[`${filterSubmitValue?.[value.groupKey]?.conditionList?.[0]},${filterSubmitValue?.[value.groupKey]?.conditionList?.[1]}`]}
              options={value?.searchConditionRespDTOList?.map((v) => {
                return { ...v, label: v?.name, value: v?.values || v?.valueId };
              })}
              onChange={(extend, _) => {
                console.log(extend,'extend');
                const INPUT_VALUE = extend?.[0]?.split(',') ? extend?.[0]?.split(',') : extend;
                const VALUE_OBJ = {
                  [`${value?.groupKey}_min`]: INPUT_VALUE?.[0],
                  [`${value?.groupKey}_max`]: INPUT_VALUE?.[1],
                };
                form.setFieldsValue(VALUE_OBJ);
                setFilterSubmitValue((data) => {
                  return {
                    ...data,
                    ...VALUE_OBJ,
                    [value?.groupKey]: {
                      conditionList:INPUT_VALUE,
                      groupName:value?.groupName
                    },
                  };
                });
              }}
            />
          )}
        </Collapse>
      );
    },
    1: (value) => {
      return (
        <Collapse
          className="range-input-with-select-collapse"
          valueGroupKey={value?.groupKey}
          title={value?.groupName}
          // arrow={() => {
          //   // 最小值
          //   const minValue = filterSubmitValue?.[value.groupKey]?.conditionList?.[0];
          //   // 最大值
          //   const maxValue = filterSubmitValue?.[value.groupKey]?.conditionList?.[1];

          //   if (minValue && maxValue) {
          //     return (
          //       <>
          //         {minValue}~{maxValue}
          //       </>
          //     );
          //   } else if (minValue && !maxValue) {
          //     return <>≥{minValue}</>;
          //   } else if (!minValue && maxValue) {
          //     return <>≤{maxValue}</>;
          //   } else {
          //     return <></>;
          //   }
          // }}
        >
          <div className="range-input-with-select-content">
            <Form.Item
              className="range-input-with-select"
              label=""
              name={`${value?.groupKey}_min`}
            >
              <Input
                placeholder="最低"
                type="number"
                // min={value?.min}
                onChange={(v) => {
                  setFilterSubmitValue((data) => {
                    return {
                      ...data,
                      [value?.groupKey]: {
                        conditionList:[v, data?.[value.groupKey]?.conditionList?.[1]],
                        groupName:value?.groupName
                      },
                    };
                  });
                }}
              />
            </Form.Item>
            <span className="space-YA-0b"></span>
            <Form.Item
              className="range-input-with-select"
              label=""
              name={`${value?.groupKey}_max`}
            >
              <Input
                placeholder="最高"
                type="number"
                // max={value?.max}
                onChange={(v) => {
                  setFilterSubmitValue((data) => {
                    return {
                      ...data,
                      [value?.groupKey]: {
                        conditionList:[ data?.[value.groupKey]?.conditionList?.[0], v],
                        groupName:value?.groupName
                      },
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
        </Collapse>
      );
    }
  }));

  const submitFrom = () => {
    let submitValue = filterSubmitValue;
    Object.keys(filterSubmitValue)?.forEach(v => {
      if(submitValue[v]?.isGroup){
        let conditionList = [], statConditionList = [];
        Object.values(submitValue[v].options).forEach(value => {
          conditionList = [...conditionList, ...value.conditionList];
          statConditionList = [...statConditionList, ...value.statConditionList];
        })
        submitValue = {
          ...submitValue,
          [v]: {
            ...submitValue[v],
            conditionList,
            statConditionList
          }
        }
        delete submitValue[v].options;
        delete submitValue[v].isGroup;
      }
    })
    onSubmit({
        ...submitValue
    })
  };

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
        let anchorElement = document.getElementById(anchorName);
        if(anchorElement) { anchorElement.scrollIntoView()}
    }
  }


  return (
    <Form
      form={form}
      onFinish={submitFrom}
      footer={
        <div className="more-filter-footer">
          <Button
            shape="default"
            color="default"
            onClick={() => {
              onCancel();
            }}
          >
            取消
          </Button>
          <Button shape="default" color="primary" type="submit">
            确认
          </Button>
        </div>
      }
    >
      {/* <Collapse
      > */}
        {filterData?.map((value, index) => {
          return filterContent?.[value?.displayType]?.(value, index);
        })}
      {/* </Collapse> */}
      <Popup
        visible={popupVisible}
        onMaskClick={() => {
          setPopupVisible(false);
        }}
        className="c-popup-select"
        position="right"
        bodyStyle={{ width: "100vw" }}
      >
        <NavBar
          className="filter-top-btn"
          onBack={() => {
            setPopupVisible(false);
            setPopIndex(0)
          }}
          right={(
            <>
              <Button onClick={() => {
                setPopIndex(0)
                setPopupValue(popValue => {
                  let newData = popValue;
                  newData.forEach(v => {
                    v.children.forEach(i => {
                      i.list.forEach(w => w.flag = false)
                    })
                  })
                  return [
                    ...newData,
                  ]
                })
              }
              }>
                重置
              </Button>
              <Button onClick={() => {
                let filterValue = []
                // 当前选项为单选
                if(popupValue[popIndex]?.radio){
                  filterValue = [filterRadio]
                } else {
                  popupValue.forEach(v => {
                    v.children.forEach(i => {
                      filterValue = filterValue.concat(i.list.filter(w => w.flag))
                    })
                  })
                }
                setFilterSubmitValue((data) => {
                  if (!popupValue[popIndex]?.radio){
                    return {
                      ...data,
                      [popupValue[popIndex].key]:  [...filterValue, ...(data?.[popupValue[popIndex].key] || [])]
                    }
                  } else {
                    const newData = data?.[popupValue[popIndex].key] || [];
                    newData[popupValue[popIndex].indexActive] = filterRadio;
                    return {
                      ...data,
                      [popupValue[popIndex].key]: newData
                    }
                  }
                })
                setPopupVisible(false);
                setPopIndex(0)
              }}>完成</Button>
            </>
          )}
        />
        <div className="select-content clearfix area-select">
          {
            popupValue?.[0]?.label && popupValue?.[0]?.value && (
              <div className="classify-wrap list-block">
                {
                  popupValue?.map((value, index) => (
                    <div
                      className={classNames("list-item", "list-item-center"," list-item-link",{
                        " selected": popIndex === index
                      })}
                      data-stat-click-counter="1"
                      onClick={() => {
                        setPopIndex(index)
                      }}
                    >
                      {value.label}
                    </div>
                  ))
                }
              </div>
            )
          }
          
          <div id="detailWrap" className="detail-wrap list-block border">
            <div className="c-classify-list">
              <div id="classifyList" className="cnt">
                {
                  popupValue[popIndex]?.children.map((v, index) => (
                    <>
                      <div className="classify" id={v.key}>
                        <span>{v.key}</span>
                      </div>
                      <div className="">
                        <ul className="">
                          {
                            v.list.map((i, v_index) => (
                              <li
                                key={i.value}
                                className={classNames("list-item list-item-link", {
                                  on: popupValue[popIndex]?.radio ? i?.value === filterRadio?.value : i.flag
                                })}
                                onClick={() => {
                                  if (popupValue[popIndex]?.radio){
                                    setFilterRadio({
                                      ...i,
                                      [popupValue[popIndex]?.keyValue]: i.value
                                    })
                                  } else {
                                    selectGroup(index, v_index)}
                                  }
                                }
                              >
                                <div
                                  
                                >{i.label}</div>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </>
                  ))
                }
                
              </div>
              <div className="letter-index-bar">
                {
                  popupValue[popIndex]?.children?.map(v => (
                    <div className="l-char" key={v.key}
                      onClick={() => {
                        scrollToAnchor(v.key)
                      }} 
                    >
                      <span className="l-text" >{v.key}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </Form>
  );
};

export default MoreFilter;
