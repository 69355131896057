import React, { useEffect, useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavBar, Swiper, ImageViewer, Tabs, Toast, Button, Steps, Space, SpinLoading, Dialog, Image } from 'antd-mobile';
import { ShopbagOutline, DownFill, RightOutline, StarOutline } from 'antd-mobile-icons';
import { getGameDetail,
          getGameXunShowType,
          getGamesAdvertise
        } from '@/api/index';
import './index.less';
import CoedMask from "../comment/coedMask"; // 导入验证码输入框
import request from '../../utils/request';

const { Step } = Steps


export default function GameDetail() {
  const TabRef = useRef(null)
  const urlSearchData = useParams();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [price, setPrice] = useState('');
  const [searchData, setSearchData] = useState('')
  const [isLoading, setLoading] = useState(true);

  const [gameDetail, setGameDetail] = useState(null);

  const [buttonInfo, setButtonInfo] = useState({});
  const [defaultActiveKey, setDefaultActiveKey] = useState('vegetableImg');

  const [gamesAdvertise, setGamesAdvertise] = useState(null)

  
  const getCookie = (cookieName) => {
    const strCookie = document.cookie
    const cookieList = strCookie.split(';')
    
    for(let i = 0; i < cookieList.length; i++) {
      const arr = cookieList[i].split('=')
      if (cookieName === arr[0].trim()) {
        return arr[1]
      }
    }
    
    return ''
  }


  const getButtonShow = async () => {
    const res = await getGameXunShowType();
    setButtonInfo(res?.data)
    const notify = res?.data?.data?.notify;
    if(notify?.showType == '1' && !getCookie('showDia')){
      document.cookie="showDia=true"
    }
    if((getCookie('showDia') == 'true' && notify?.showType == '1') || notify?.showType == '0'){
      Dialog.alert({
        content: (
          <div>
            {
              notify?.img?.map(v => (
                <img style={{width: '100%'}} src={v} />
              ))
            }
            <p>{notify?.text}</p>
          </div>
        ),
        onConfirm:() => {
          document.cookie="showDia=false";
        },
        onClose: () => {
          document.cookie="showDia=false";
        },
        confirmText: '确定'
      });
    }
  }

  const encryption = (data) => {
    let enData = '';
    const signData = CryptoJS.MD5(data).toString();
    for(let i = 0; i < signData.length; i++){
      if(i%2 !== 0){
        enData += signData[i - 1];
      }
    }
    return enData;
  }

  const getGamesAdvertiseData = async () => {
    const res = await getGamesAdvertise({gameId:search.get('gameid  '), class: 3});
    setGamesAdvertise(res?.data?.data)
  } 

  useEffect(() => {
    getButtonShow();
    getGamesAdvertiseData();
    // if(!getCookie('showDia')){
    //   document.cookie="showDia=true"
    // }
    // if(getCookie('showDia') == 'true'){
    //   Dialog.alert({
    //     content: (
    //       <div>
    //         <img style={{width: '100%'}} src={require('../../config/img/spring.png')} />
    //         <p>【安全提醒】近期很多店铺使用我们商城链接诈骗，请认准官方指定店铺【喜号游戏官方旗舰店】，其他店铺均是骗子!且没有售后容易找回! 请认准【喜号游戏官方旗舰店】 ，核实请点击咨询喜号客服链接能跳转到拼多多店铺查询!</p>
    //       </div>
    //     ),
    //     onConfirm:() => {
    //       document.cookie="showDia=false"
    //     },
    //     confirmText: '确定'
    //   });
    // }
    const t = (Date.parse(new Date())).toString();
    const data = JSON.stringify({id: urlSearchData?.id});
    const sign = CryptoJS.MD5(`${encryption(data)}${encryption(t)}`).toString();
    getGameDetail({
      data,
      t,
      sign,
      mode: search.get('mode')
    }).then(res => {
      const { data } = res;
      setGameDetail(search.get('mode') == 0 ? data?.data : data?.data?.data);
    }).finally(() => {
      setLoading(false);
    })
  }, [])
  const goRouter = (URL) => {
    const ROUTER = `${URL}&Url=${encodeURI((window.location.hash).split('#')?.[1])}`
    const user = navigator.userAgent;
      //android端
      const isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      const isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1; 
      if(isiOS) {  
        window.location.href = ROUTER;
      }else{ 
        window.open(ROUTER, '_blank')
      }
      
  }
  // const goCustomer = () => {
  //   const URL = `https://mobile.yangkeduo.com/chat_detail.html?mall_id=372330997&amp;fromQrCode=1&refer_page_name=chat_detail&refer_page_id=10041_1708908346921_6rnluh0ib3&refer_page_sn=10041&Url=${window.location.href}`;
  //   goRouter(URL)
  // }
  // const goBuy = () => {
  //   const URL = `https://mobile.yangkeduo.com/mall_page.html?mall_id=372330997&msn=xtlfhtl63xp3wp2ggw22wzrxje_axbuy&_x_outbound_channel=__outbound_mall__${window.location.href}`;
  //   goRouter(URL)
  // }
  //p-game-detail-yiluwangw
  return (
    <div className="p-game-detail">
      <div className="p-game-detail-header">
      </div>
      <NavBar
          className="p-game-detail-head"
          onBack={() => navigate(-1)}
        >{gameDetail?.gameName || '账号详情'}</NavBar>
        <div className="detail-img-box">
          <Swiper
            loop
            autoplay
            className='img-swiper'
          >
            {
              (gameDetail?.images || gameDetail?.tags?.[0]?.tag?.[0]?.values)?.map((value, index) => (
                  <Swiper.Item key={value?.imageId}>
                    <img
                      src={value?.originImageUrl || value}
                      onClick={() => {
                        ImageViewer.Multi.show({
                          images: gameDetail?.images?.map(value => value?.originImageUrl || value),
                          defaultIndex: index
                        })
                      }}
                      alt=""
                    />
                  </Swiper.Item>
              ))
            }
          </Swiper>
      </div>
      <div className="product-detail-info">
        {
            isLoading && (
              <Space direction='horizontal' className='detail-loading' wrap block >
                <SpinLoading style={{ '--size': '56px' }} color='white' />
              </Space>
            )
        }
        
        <div
          className='buy_area new_ver'
        >
          <div className="priceWrap">
            <span className='price large_size'>
              <em>￥</em>{(gameDetail?.price * 1 || 0).toFixed(2)}
            </span>
          </div>
          <div className='fn fn_goods_name'>
            <div className="fn_text_wrap">
              {gameDetail?.title}
            </div>
            {search.get('mode') == 1 && <div className="fn_text_warp_sub_title">
              {gameDetail?.sub_title}
            </div>}
          </div>
          <div className="game-detail-update-time">
            <span>上架时间：{gameDetail?.updateTimeWithin || gameDetail?.upper_at}</span>
            <span>99+人想要</span>
          </div>
        </div>
        {
          gamesAdvertise?.res_url && (
            <Image
              src={gamesAdvertise?.res_url}
              fit='cover'
              onClick={() => goRouter(gamesAdvertise?.url)}
              height="auto"
            />
          )
        }
        
        <div className="game-info game-copy-info">
          <span>商品ID</span>
          <div className='product-info'>
            <p>{urlSearchData?.id}</p>
            <span>点击复制，粘贴发送给最下方客服</span>
          </div>
          <CopyToClipboard
              text={ `${gameDetail?.title};id：${urlSearchData?.id};￥${gameDetail?.price}`}
            >
              <Button
                size='small'
                color='primary'
                onClick={() => {
                  Toast.show({
                    content: (
                        <div className='small-text'>
                          <span>商品ID复制成功</span>
                          <span>发送到{buttonInfo?.name}</span>
                        </div>
                      )
                  })
                  if (!buttonInfo?.data?.notify?.text) {
                    goRouter(buttonInfo?.data?.button_list?.button_item_shop?.[0]?.url)
                    return;
                  }
                  Dialog.alert({
                    content: (
                      <div>
                        <p>{buttonInfo?.data?.notify?.text}</p>
                      </div>
                    ),
                    onConfirm:() => {
                      goRouter(buttonInfo?.data?.button_list?.button_item_shop?.[0]?.url)
                    },
                    confirmText: '确定'
                  });
                }}
              >
                一键复制
              </Button>
          </CopyToClipboard>
        </div>
        {
          search.get('mode') == 0 && (
          <div className="game-info game-detail-info-box">
            <div className="info-list">
              {
                (gameDetail?.contents)?.map((value, index) => (
                  index < 6 && (
                  <div className="info-item">
                    <span>{value?.values[0]?.split(',')?.length > 1 ? value?.values[0]?.split(',')?.length + '个' : value?.values[0]?.split(',')?.join('  ')}</span>
                    <span>{value?.name}</span>
                  </div>)
                ))
              }
              
              
            </div>
            <div className="more-detail-info"
              onClick={() => {
                setDefaultActiveKey('vegetables');
                setTimeout(() => {
                  document.querySelector("#roll1_top").scrollIntoView(true);
                })
              }}
            >全部参数<div><DownFill /></div></div>
          </div>
          ) 
        }
        <div className="game-info game-detail-step">
          <div className="game-step-title">
            <span>官方保障</span>
            <p>永久包赔 签署合同 黑号拦截</p>
          </div>
          <div className="step-list">
            <div className="step-list-item">
              <span>流程</span>
              <p>下单支付 <RightOutline /> 验号换绑<RightOutline />交易成功</p>
            </div>
            <div className="step-list-item">
              <span>政策</span>
              <p>不支持未成年人购买</p>
            </div>
          </div>
        </div>
        {
          buttonInfo?.reminder && (
            <div className='img-box'>
              <img
                src={`https://www.baodianyou.com${buttonInfo?.reminder}`} alt=""
                onClick={() => {
                  const user = navigator.userAgent;
                  //android端
                  const isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1; 
                  //ios端
                  const isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 
                  if(isiOS) {  
                      window.location.href = buttonInfo?.url;
                  }else{ 
                    window.open(buttonInfo?.url)
                  }
                }}
                />
            </div>
          )
        }
        <div id="roll1_top">
          <Tabs
            className='mod_fix_wrap'
            defaultActiveKey="vegetableImg"
            activeKey={defaultActiveKey}
            onChange={ key => {
              setDefaultActiveKey(key)
            }}
          >
            <Tabs.Tab title='账号截图' key='vegetableImg'>
                <div className='product-detail'>
                  {
                    (gameDetail?.images || gameDetail?.tags?.[0]?.tag?.[0]?.values)?.map(value => (
                      <img src={value?.originImageUrl || value} />
                    ))
                  }
                </div>
                <div className="product-token">
                  <p>1.请勿私下联系卖家交易，防止被骗。</p>
                  <p>2.所展示的商品供求信息由买卖双方自行提供，其真实性、准确性和合法性由信息发布人负责。</p>
                  <p>3.买家付款后可与客服联系查看账号最新截图,商品信息真实性由卖家负责。因网易游戏和微信游戏特殊性，商品信息由卖家实时截图提供。</p>
                  <p>4.国家法律规定，未成年人不能参与虚拟物品交易。</p>
                  <p>5.本平台提供的数字化商品根据商品性质不支持七天无理由退货及三包服务。</p>
                </div>
              </Tabs.Tab>
              <Tabs.Tab title='账号参数' key='vegetables' >
                <div className="product-info" >
                  {
                    (gameDetail?.contents || gameDetail?.tags?.[1]?.tag?.[0]?.values)?.map(value => (
                      <div className="product-info-list">
                        {
                          search.get('mode') == 0 ? (
                            <>
                              <span>{value?.name}</span>
                              <p>{value?.values[0]?.split(',')?.join('  ')}</p>
                            </>
                          ) : (
                            <>
                              <span>{value?.split('：')[0]}</span>
                              <p>{value?.split('：')[1]}</p>
                            </>
                          )
                        }
                        
                      </div>
                    ))
                  }
                </div>
                <div className='product-detail'>
                  {
                    gameDetail?.imgList?.map(value => (
                      <img src={value?.middle} />
                    ))
                  }
                </div>
                <div className="product-token">
                  <p>1.请勿私下联系卖家交易，防止被骗。</p>
                  <p>2.所展示的商品供求信息由买卖双方自行提供，其真实性、准确性和合法性由信息发布人负责。</p>
                  <p>3.买家付款后可与客服联系查看账号最新截图,商品信息真实性由卖家负责。因网易游戏和微信游戏特殊性，商品信息由卖家实时截图提供。</p>
                  <p>4.国家法律规定，未成年人不能参与虚拟物品交易。</p>
                  <p>5.本平台提供的数字化商品根据商品性质不支持七天无理由退货及三包服务。</p>
                </div>
              </Tabs.Tab>
              
          </Tabs>
        </div>
        
      </div>
      <div className="de-row-footer"
        
      >
        {
          buttonInfo?.data?.button_list?.button_item_shop?.map((v)=>(
            <Button style={{width: '100%'}} onClick={()=>goRouter(v?.url)} color='primary'>
              <img src={v?.ico}/>
              {v?.value}
            </Button>
          ))
        }
            {/* <div className='de-row-footer-left'>
              <div onClick={goBuy}><ShopbagOutline /><span>店铺</span></div>
              <div><StarOutline /><span>收藏</span></div>
            </div>
            <div className="de-row-footer-right">
              <div onClick={goCustomer}>聊一聊</div>
              <div
                onClick={goBuy}
              >
                立即购买
              </div>
            </div> */}
      </div>
      <CoedMask lock={buttonInfo?.lock} buttonListServer={buttonInfo?.data?.button_list?.button_list_server} />
    </div>
  )
}
