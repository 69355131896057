import React from 'react';
import { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { withCache } from './withCache'
import List from '@/view/list'
import Detail from '@/view/detail';

const Home = lazy(() => import('@/view/home'));
const FilterList = lazy(() => import('@/view/game-list'));
const AllFilterList = lazy(() => import('@/view/all-game-list'));
const Login = lazy(() => import('@/view/login'));

const CacheAbleList = withCache(List)

// 路由懒加载，需配合Suspense使用
const lazyLoad = (children) => {
  return <Suspense fallback={<h2>Loading..</h2>}>{children}</Suspense>;
};

const AppRouter = ()   => {
  return useRoutes([
    {
        path: '/',
        element: lazyLoad(<Home />),
    },
    {
        path: '/gamelist',
        element: <CacheAbleList />,
        children: [{ path: ':id', element: <Detail /> }],
    },
    { path: '/filter-list', element: lazyLoad(<FilterList />) },
    { path: '/all-list', element: lazyLoad(<AllFilterList />) },
    { path: '/login', element: lazyLoad(<Login />) },
  ]);
};

export default AppRouter;