import React, { useEffect, useState } from 'react';
import { Toast, Button } from 'antd-mobile';
import CryptoJS from 'crypto-js';
import { checkPswLock } from '@/api/index';

import CodeInput from "../CodeInput"; // 导入验证码输入框
import './index.less'
function CoedMask({
  onCallback,
  lock,
  buttonListServer
}) {
  const [isShowMask, setMask] = useState(false);
  const [vertifyCodes, setVertifyCode] = useState(""); // 保存当前输入的验证码

  // 验证码改变事件
  const handleChange = (value) => {
    setVertifyCode(value); // 更新验证码
  };

  const getCustomer = () => {
    const url = buttonListServer?.[0]?.url;
    const URL = 'https://mobile.yangkeduo.com/mall_page.html?mall_id=372330997&msn=xtlfhtl63xp3wp2ggw22wzrxje_axbuy&_x_outbound_channel=__outbound_mall__';
    const ROUTER = `${url || URL}&Url=${encodeURI((window.location.hash).split('#')?.[1])}`;
    return ROUTER
  }

  // 验证码输入完成后进行校验
  const handleComplete = (vertifyCode) => {
    // 验证码输入后验证逻辑
    console.log("验证码输入完毕", vertifyCode);
  };
  const onSubmitCode = () => {
    checkPswLock({
      sign: CryptoJS.MD5(vertifyCodes).toString()
    }).then( res => {
      if(!res?.data?.data?.sign){
        Toast.show({
          icon: 'fail',
          content: '密码错误',
        })
        setVertifyCode("")
      } else {
        setMask(false);
        Toast.show({
          icon: 'success',
          content: '密码正确',
        });
        onCallback?.(res?.data?.data?.sign)
        document.cookie=`code=${vertifyCodes}`
        document.cookie=`SIGN=${res?.data?.data?.sign}`
      }
    }).catch(err => {
      Toast.show({
        icon: 'fail',
        content: '密码错误',
      })
      setVertifyCode("")
    })
  }

  useEffect(() => {
    setMask(lock && CryptoJS.MD5(getCookie("code")).toString() != lock)
  }, [lock])

  const getCookie = (cookieName) => {
    const strCookie = document.cookie
    const cookieList = strCookie.split(';')
    
    for(let i = 0; i < cookieList.length; i++) {
      const arr = cookieList[i].split('=')
      if (cookieName === arr[0].trim()) {
        return arr[1]
      }
    }
    
    return ''
  }

  return (
    <>
    {
      isShowMask && (
        <div className="mask-content adm-center-popup" >
          <div className="mask"></div>
          <div className="mask-content-com adm-center-popup">
            <div className="title">请输入访问密码</div>
            <div className="adm-center-popup-body adm-dialog-body">
                <CodeInput
                  vertifyCode={vertifyCodes}
                  onChange={handleChange}
                  onComplete={handleComplete}
                />
            </div>
                <div className="footer adm-dialog-footer">
                  <div className="adm-dialog-action-row">
                    <Button color='primary' onClick={onSubmitCode}>确定</Button>
                    <a className='adm-button adm-button-shape-default' href={getCustomer()} target="__blank">{buttonListServer?.[0]?.value}</a>
                  </div>
                </div>
            </div>
        </div>
      )
    }
      
    </>
  );
}

export default CoedMask;
