import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import classNames from 'classnames';
import {
    NavBar,
    Dropdown,
    InfiniteScroll,
    List,
    SearchBar,
    PullToRefresh,
    Image,
    Space,
    SpinLoading,
    TreeSelect,
    CheckList,
    Empty,
    ImageViewer,
    Checkbox,
    Button,
    Radio,
    SideBar,
    Grid,
    DotLoading,
    Input,
    Dialog,
    Toast
  } from 'antd-mobile';
import CoedMask from "../comment/coedMask"; // 导入验证码输入框
import { DownOutline, UserContactOutline, UserOutline, StarFill, SearchOutline } from 'antd-mobile-icons';
import { getGameClassList,
          getSearchCondition,
          getGameServerList, 
          getGoodsList,
          getGameXunShowType,
          getConditionsForSearch,
          getByCondition,
          getGamesAdvertise
        } from '@/api/index';
import MoreFilter from './moreFilter';
import './index.less';
import { data } from 'browserslist';


const InfiniteScrollContent = ({ hasMore, data}) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : (
        data?.data?.length ? <span>--- 我是有底线的 ---</span> : <Empty description='暂无数据' />
      )}
    </>
  )
};

const GameList = () => {
  const pingtaiRef = useRef()
 const [search, setSearchParams] = useSearchParams();
 const navigate = useNavigate();
 const [hasMore, setHasMore] = useState(true)
 const [cookie, setCookie] = useState()
 const [gameList, setGameList] = useState([]);
 const [filterTabList, setFilterTabList] = useState([])
 const [firstFilterList, setFirstFilterList] = useState([]);
 const [secondFilterList, setSecondFilterList] = useState([]);
 const [thirdFilterList, setThirdFilterList] = useState([]);
 const [sortList, setSortList] = useState({});

 const [searchData, setSearchData] = useState({});
 //rootId
 const [rootId, setRootId] = useState(0);
 // cid
 const [cId, setCid] = useState(null);

 const [page, setPage] = useState(1);

 const [isMore, setMore] = useState(false);

 const [isLoading, setLoading] = useState(false);
 const [imgShow, setImgShow] = useState(false);

 const [moreFilterValue, setMoreFilterValue] = useState({});

 const [searchText, setSearchText] = useState('');
 const [key, setKey] = useState('');

 const [buttonInfo, setButtonInfo] = useState({});

  //第一个筛选项子数据
 const [firstFilterChild, setFirstFilterChild] = useState([]);
 const [filterFilterOneChild, setFilterFilterOneChild] = useState(0);
 
 const [activeKey, setActiveKey] = useState('')

 // 服务器搜索关键字
 const [searchTerm, setSearchTerm] = useState(null);
 const [childHasMore, setChildHasMore] = useState(false);
  const [listPage, setListPage] = useState(1);
  const [isChildMore, setChildMore] = useState(true);

  const [fixedFlag, setFixedFlag] = useState(false);
  const [gameInfo, setGameInfo] = useState({});

  const [gameClientName, setGameClientName] = useState('');

  const [gameMode, setGameMode] = useState(null);

  const [defaultKey, setDefaultKey] = useState([]);

  const [gamesAdvertise, setGamesAdvertise] = useState(null);


 useEffect(() => {
  if(searchTerm != null){
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm, 1)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }
}, [searchTerm])    

useEffect(() => {
  getButtonInfo();
  getGamesAdvertiseData();
}, [])

const callbackMask = (sign) => {
  getGameListApi(sign);
}

  const getGamesAdvertiseData = async () => {
    const res = await getGamesAdvertise({gameId:search.get('id'), class: 2});
    setGamesAdvertise(res?.data?.data)
  } 

  const getButtonInfo = async() => {
    const res = await getGameXunShowType();
    const LOCK =res?.data?.lock;
    if(!LOCK || CryptoJS.MD5(getCookie("code")).toString() == LOCK){
      getGameListApi(getCookie("SIGN") || null);
    }
    setButtonInfo(res?.data)
  }

  const onSearch = async(value, filterPage) => {
    try {
      const res = await getByCondition({
        page: filterPage,
        cId,
        keyword: value
      })
      setFirstFilterChild(child => {
        return {
          ...child,
          [activeKey]:  {
            data: filterPage == 1 ? (res?.data?.data || []) : [...(child?.[activeKey] || []), ...(res?.data?.data || [])],
            page: filterPage
          }
        }
      })
      setChildHasMore(res?.data?.data?.length || 0 > 0);
      setChildMore(res?.data?.data?.length || 0 > 0);
    } catch (err) {
      setChildHasMore(false)
    }
    finally {
      setLoading(false)
    }
  }
 
  const  childLoadMore = () => {
    if(isChildMore){
      onSearch(searchTerm, (firstFilterChild?.[activeKey]?.page || 0) + 1);
      setChildMore(false)
    }
  }

  const loadMore = async(value) => {
    if(isMore){
      getList({
        page: Number(page) + 1,
        Cookie: cookie
      })
      setMore(false)
    }
  }
  const getCookie = (cookieName) => {
    const strCookie = document.cookie
    const cookieList = strCookie.split(';')
    
    for(let i = 0; i < cookieList.length; i++) {
      const arr = cookieList[i].split('=')
      if (cookieName === arr[0].trim()) {
        return arr[1]
      }
    }
    
    return ''
  }

  const setSearchParamsValue = (value, gameInfoValue) => {
    const LIST_DATA = {
        "modelType": "h5",
        "page": "1",
        "pageSize": "10",
        "relateId": "10101"
    }
    const MODE_LIST_DATA = {
      "page": "1",
      "gameId": gameInfoValue?.data?.id || gameInfo?.data?.id
    }
    let DATA = (gameInfoValue?.mode || gameMode) == 0 ? LIST_DATA : MODE_LIST_DATA
    setSearchData(data => {
      return {
        ...DATA,
        ...data,
        ...value
      }
    })
    return {
      ...DATA,
      ...searchData,
      ...value
    }
  }

  const encryption = (data) => {
    
    let enData = '';
    const signData = CryptoJS.MD5(data).toString();
    for(let i = 0; i < signData.length; i++){
      if(i%2 !== 0){
        enData += signData[i - 1];
      }
    }
    return enData;
  }
  
  const delCookie = (cname) =>{
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  const getList = (value = {}, gameInfoValue) => {
    const t = (Date.parse(new Date())).toString();
    const pageIndex = value?.page || page;   
    const mode = gameInfoValue ? gameInfoValue?.mode : gameMode;                       
    if(pageIndex === 1){                          
      setLoading(true)                          
      setGameList([])
    }
    getGoodsList({
      data: JSON.stringify(setSearchParamsValue(value, gameInfoValue)),
      mode,
      sign:value?.sign || searchData?.sign
      
    }).then(res => {
      if(res?.data?.code == -1){
        delCookie('code')
        getButtonInfo()
      } else {
        const { data } = res || {};
        const gameListValue = mode == 0 ? data?.data : data?.data?.list;
        setGameList(value => {
          return pageIndex === 1 ? (gameListValue || [] ): value?.concat(gameListValue || [])
        });
        setHasMore(gameListValue?.length > 0)
        if(pageIndex === 1){
          setCookie(res?.data?.Cookie)
        }
        setPage(pageIndex);
        setMore(true);
      }
      
    }).finally(() => {
      setLoading(false);
    })
  }
  const getGameListApi = (listSign) => {
    const t = (Date.parse(new Date())).toString();
    const sign = CryptoJS.MD5(`${window.location.host}${CryptoJS.MD5(search.get('id'))}${t}`).toString();
    getGameClassList({
      gameId: search.get('id'),
      t,
      sign
    }).then(res => {
      setGameInfo(res?.data);
      setCid(res?.data?.data?.[0]?.id)
      setGameMode(res?.data?.mode);
      getConditionsForSearch({
        mode: res?.data?.mode,
        cId: res?.data?.data?.[0]?.id || res?.data?.data?.id,
      }).then(data => {
        const filterFilterOne = data?.data?.data?.[0];
        const filterFilterThird = data?.data?.data?.[2];
        setFirstFilterList(filterFilterOne);
        setFirstFilterChild({
          [filterFilterOne?.[0]?.value]: {
            data: filterFilterOne?.[0]?.data,
            page: 1
          }
        });
        setChildMore(filterFilterOne?.[0]?.data?.length || 0 > 0)
        setActiveKey(filterFilterOne?.[0]?.value);
        setGameClientName(filterFilterOne?.[0]?.name)
        setThirdFilterList(filterFilterThird);
        setSortList(data?.data?.data?.[1])
        if (res?.data?.mode == 1){
          setDefaultKey(getDefaultKey(data?.data?.data?.[2]?.options, 'type', 'checkbox', 'id'));
          console.log(data?.data?.data?.[2], 'data?.data?.data?.[2]');
          setSecondFilterList(data?.data?.data?.[2]?.options?.map(v => {
            return {
              groupName: v?.title,
              groupKey: (v?.id).toString(),
              displayType: 3,
              flag: false,
              searchConditionRespDTOList: v?.val?.map(w => {
                return {
                  name: w?.title,
                  valueId: w?.id
                }
              })
            }
          }))
        }
      })

      res?.data?.mode == 0 && getSearchCondition({
        cId: res?.data?.data?.[0]?.id,
        gameId: search.get('id')
      }).then(filter => {
        setDefaultKey(getDefaultKey(filter?.data?.data, 'displayType', 3, 'groupKey'))
        setSecondFilterList(filter?.data?.data?.map(v => {
          return {
            ...v,
            flag: false
          }
        }))
      })
      setFilterTabList(res?.data?.data || []);
      setRootId(res?.data?.data?.[search?.get('type') || 0]?.id);
      console.log(res?.data?.data?.[search?.get('type') || 0]);
      const GAME_INFO = res?.data?.data?.[search?.get('type') || 0];
      getList({
        sign: listSign,
        gameCondition: {
          platformId: res?.data?.data?.[0]?.platform ,
          clientId: res?.data?.data?.[0]?.id,
          gameId: res?.data?.mode == 0 ? search?.get('id') : res?.data?.data?.id
        },
        categoryId: res?.data?.data?.[0]?.id,
        parentId: res?.data?.data?.[0]?.parentId,

        stdCatId: GAME_INFO?.parentId,
        systerName: GAME_INFO?.name,
        platformId: GAME_INFO?.platform,
        jymCatId: GAME_INFO?.id,
        categoryName: GAME_INFO?.categoryName
      }, res?.data)
    }).catch(err => {
    }).finally(() => {
    })
  };
  const goGameList = (item) => {
    navigate(`/gamelist/${item?.goodsId || item?.id}?mode=${gameMode}&gameid=${search.get('id')}`)
  }

    /**默认展开筛选项 */
    const getDefaultKey = (data, key, value, groupKey) => {
      return data?.map((v) => {
        if(v?.[key] !== value){
          return v?.[groupKey]
        }
      })?.filter( v => v);
    }

  useEffect(() => {
      
      // getButtonShow()
  }, [])

  const goCustomer = (url) => {
    const URL = 'https://mobile.yangkeduo.com/mall_page.html?mall_id=372330997&msn=xtlfhtl63xp3wp2ggw22wzrxje_axbuy&_x_outbound_channel=__outbound_mall__';
    const ROUTER = `${url || URL}&Url=${encodeURI((window.location.hash).split('#')?.[1])}`;
    const user = navigator.userAgent;
      //android端
      const isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1; 
      const isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if(isiOS) {  
          window.location.href = ROUTER;
      }else{ 
        window.open(ROUTER, '_blank')
      }
  }

  const onSubmit = (value) => {
    getList({
      [gameMode == 0 ? 'searchCondition' : 'gameCondition']:{...value},
      page:1
    })
    pingtaiRef?.current?.close();
  }
  const onCancel = (value) => {
    pingtaiRef?.current?.close();
  }

  // p-game-list-yiluwangw
  // p-game-list-wukong
  return (
    <div
      className={
        classNames('p-game-list',{
          'p-game-list-event': imgShow
        })
      }
    >
      <div className="p-game-header" id="p-game-header">
        <NavBar
          className="p-game-head"
          onBack={() => {
            localStorage.clear()
            navigate(-1)
          }}
          right={<UserOutline />}
        >
          <Image
            src={gameMode == 0 ? gameInfo?.icoUrl : gameInfo?.data?.icoUrl}
            fit='cover'
            width={25}
            height={25}
          />
          {gameMode == 0 ? gameInfo?.gameName : gameInfo?.data?.gameName}
        </NavBar>
        <div  className={classNames(fixedFlag ? "show-fixed-menu" : "hidden-fixed-menu")}>
          <div className="search-box">
            <SearchOutline />
            <Input
              onChange={ value => setSearchText(value)}
                value={searchText}
                placeholder='请输入关键词查找'
              />
            <Button onClick={() => {
                getList({
                  keyword: searchText,
                  page: 1
                })
              }}>搜索</Button>
          </div>
          <div className="p-game-filter" >
            <Dropdown ref={pingtaiRef} arrow={<DownOutline />}>
              {
                gameMode == 0 ? (
                  <Dropdown.Item
                    key='sorter'
                    title='客户端'
                  >
                    <div className="account-number-group">
                        <SideBar
                          onChange={value => {
                            setFilterFilterOneChild(0);
                            setActiveKey(value);
                            setGameClientName(firstFilterList?.filter(v => v?.value === value)?.[0]?.name)
                            setSearchTerm(null);
                            !firstFilterChild?.[value] && getGameServerList({
                              cId,
                              clientId: value
                            }).then(res => {
                              const { data } = res;
                              setFirstFilterChild(child => {
                                return {
                                  [value]: {
                                    data: data?.data,
                                    page: 1
                                  },
                                  ...child
                                }
                              })
                            }).finally(() => {
                              setChildHasMore(false)
                            })
                          }}
                        >
                          {firstFilterList?.map(item => (
                            <SideBar.Item
                              key={item?.value}
                              title={item?.name}
                            />
                          ))}
                        </SideBar>
                        <div
                          className='account-number-main'
                        >
                          <SearchBar
                            placeholder='请输入关键词查找'
                            clearOnCancel={false}
                            onChange={(e) => {setSearchTerm(e);}}
                          />
                          <div className="number-main-list">
                          
                          <Grid columns={1} gap={8} className='all-number'>
                            <Grid.Item
                              onClick={() => {
                                setFilterFilterOneChild(0);
                                pingtaiRef?.current?.close()
                                getList({
                                  page: 1,
                                  serverName: '全部服务器',
                                  clientId: activeKey,
                                  serverId: '',
                                  clientName: gameClientName
                                })
                              }}
                              className={classNames('adm-grid-item-dom', {
                                "adm-grid-item-active": filterFilterOneChild == 0
                              })}
                            >
                              全部服务器
                            </Grid.Item>
                          </Grid>
                          <Grid columns={2} gap={8} className='number-item-list'>
                            {
                              firstFilterChild?.[activeKey]?.data?.map(value => (
                                <Grid.Item
                                  onClick={() => {
                                    setFilterFilterOneChild(value?.value);
                                    pingtaiRef?.current?.close()
                                    getList({
                                      clientId: activeKey,
                                      serverId: value?.value,
                                      serverName: value?.name,
                                      clientName: gameClientName,
                                      page: 1
                                    })
                                  }}
                                  className={classNames('adm-grid-item-dom',{
                                    "adm-grid-item-active": filterFilterOneChild == value?.value
                                  })}>
                                
                                  {value?.name}
                                </Grid.Item>
                              ))
                            }
                            <Grid.Item
                                span={2}
                            >
                                <InfiniteScroll threshold={5} loadMore={childLoadMore} hasMore={childHasMore}>
                                  <InfiniteScrollContent hasMore={childHasMore} data={firstFilterChild?.[activeKey]}/>
                                </InfiniteScroll>
                            </Grid.Item>
                          </Grid>
                          
                          </div>
                        </div>
                    </div>
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                  key='sorter'
                  title={firstFilterList?.name}
                  >
                  <CheckList
                    className='account-number-list'
                    defaultValue={[firstFilterList?.options?.[1]]}
                    onChange={value => {
                      pingtaiRef?.current?.close()
                      getList({
                        area_id: value?.[0]?.id,
                        page: 1
                      })
                    }}
                  >
                      {
                        firstFilterList?.options?.map(value => (
                            <CheckList.Item value={value}>{value?.title}</CheckList.Item>
                        ))
                      }
                  </CheckList>
                </Dropdown.Item>
                )
              }
              {
                gameMode == 0 && (
                  <Dropdown.Item
                    key='pingtai'
                    title={filterTabList?.filter(v => v?.id === rootId)?.[0]?.name}
                  >
                    <Radio.Group
                      className='account-number-list'
                      defaultValue={filterTabList?.[search?.get('type')] || filterTabList?.filter(v => v?.id === rootId)?.[0]}
                      onChange={value=> {
                        setRootId(value?.id)
                        pingtaiRef?.current?.close();
                        getList({
                              stdCatId: value?.parentId,
                              systerName: value?.name,
                              platformId: value?.platform,
                              jymCatId: value?.id,
                              categoryName: value?.categoryName,
                              page: 1,
                              gameCondition:{
                                platformId: value?.platform,
                                clientId: value?.id,
                                gameId: gameMode == 0 ? search.get('id') : gameInfo?.data?.id
                              }
                          })
                        setSearchParams({
                          id: search.get('id'),
                          type: value === '2' ? 0 : 1
                        })
                      }}
                    >
                      <Space direction='vertical' block>
                        {
                          filterTabList?.map(value => (
                              <Radio
                              block
                                key={value}
                                value={value}
                              >
                                {value?.name}
                              </Radio>
                          ))
                        }
                      </Space>
                    </Radio.Group>
                  </Dropdown.Item>
                )
              }
              <Dropdown.Item
                key='bizop'
                title='排序'
              >
                <CheckList
                  className='account-number-list'
                  defaultValue={[gameMode == 0 ? thirdFilterList?.[1] : sortList?.options?.[0]]}
                  onChange={value => {
                    pingtaiRef?.current?.close();
                    const gameSort = {
                      order: value?.[0]?.value,
                      page: 1
                    }
                    const gameBiZop = {
                      sortPanelName: value?.[0]?.name,
                      sortId: value?.[0]?.id,
                      page:1
                    }
                    getList(gameMode == 0 ? gameBiZop : gameSort)
                  }}
                >
                    {
                      (gameMode == 0 ? thirdFilterList : sortList?.options)?.map(value => (
                          <CheckList.Item value={value}>{value?.name}</CheckList.Item>
                      ))
                    }
                </CheckList>
              </Dropdown.Item>
                <Dropdown.Item key='more' title='筛选' forceRender={true}>
                <div className="more-filter">
                  <MoreFilter
                    filterData={secondFilterList}
                    // defaultKey={defaultKey}
                    onSubmit={onSubmit}
                    mode={gameMode}
                    onCancel={onCancel}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>
      <div
        id="SecMenu"
        className="p-game-list-data"
        style={{
          overflowY: isLoading ? 'hidden' : 'scroll',
          height: `calc(100% - ${document.getElementById('p-game-header')?.offsetHeight}px)`
        }}
      >
      {
          isLoading && (
            <Space direction='horizontal' className='list-loading' wrap block >
              <SpinLoading style={{ '--size': '56px' }} color='white' />
            </Space>
          )
      }
      {
            gameList?.length ? (
              <>
                <PullToRefresh
                  onRefresh={async () => {
                    getList({
                      page: 1
                    })
                  }}
                >
                  <List>
                    {
                        gamesAdvertise?.res_url && (
                          <List.Item
                            arrow={false}
                            key='img'
                          >
                            <Image
                              className='p-list-advertise'
                              src={gamesAdvertise?.res_url}
                              fit='cover'
                              onClick={() => goCustomer(gamesAdvertise?.url)}
                              height="auto"
                            />
                          </List.Item>
                      )
                      }
                  
                      {
                        gameList?.map((item, index) => {
                            return (
                              <>
                              {
                                gameMode == 0 ? (
                                  <List.Item
                                    arrow={false}
                                    key={index}
                                    className="list-item"
                                  >
                                    <div
                                      className='list-item-title'
                                    >
                                      <div className="p-game-list-img">
                                        <span className='img-num'>{item?.images?.length}图</span>
                                        <Space wrap>
                                            <Image
                                              src={item?.images?.[0]?.auditImage}
                                              onClick={() => {
                                                setImgShow(true)
                                                ImageViewer.Multi.show({
                                                  images: item?.images?.map(value => value?.originImage || value),
                                                  onClose: () => {
                                                    setImgShow(false);
                                                  }
                                                })
                                              }}
                                            />
                                        </Space>
                                        <div className="tips" onClick={() => goGameList(item)}>
                                            <div
                                              className="tip blue"
                                            >
                                              {item?.categoryName}
                                            </div>
                                            {
                                              item?.serverName && (
                                                <>
                                                  <i></i>
                                                  <div
                                                      className="tip"
                                                    >
                                                      {
                                                        item?.serverName
                                                      }
                                                    </div>
                                                </>
                                              )
                                            }
                                            <i></i>
                                            <div
                                              className="tip"
                                            >
                                              {filterTabList?.filter(v => v?.id === rootId)?.[0]?.name}
                                            </div>
                                            
                                      </div>
                                      </div>
                                      <div className='game-detail-info' >
                                        <div className='game-info' onClick={() => goGameList(item)}>
                                            <div className='title'  >
                                            {item?.title}
                                            </div>
                                            <div className="game-tag-list">
                                              {(item?.sellPoints || [])?.map(v => (
                                                  <span>{v?.tagName}</span>
                                                ))}
                                            </div>
                                            <div className='tag-box'>
                                                
                                                <div className="tag-list">
                                                  <div className="tag-item">
                                                    找回包赔
                                                  </div>
                                                  <div className="tag-item">
                                                    官方验号
                                                  </div>
                                                  <div className="tag-item">
                                                    安全保障
                                                  </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="item-detail" onClick={() => goGameList(item)}>
                                          <div className='item-price'>￥{(item?.price*1 || 0)?.toFixed(2)}</div>
                                            {
                                              buttonInfo?.name && 
                                              <Button
                                                shape='default'
                                                color='primary'
                                                onClick={() => {
                                                  window.open(`${buttonInfo?.url}`);
                                                }}
                                              >
                                                {buttonInfo?.name}
                                              </Button>
                                            }
                                        </div>
                                        <div className="game-customer">
                                        {
                                            buttonInfo?.data?.button_list?.button_list_server?.map((v)=>(
                                              <Button onClick={()=>goCustomer(v?.url)} color='primary'>
                                                {v?.ico && <img src={v?.ico}/>}
                                                {v?.value}
                                              </Button>
                                            ))
                                          }
                                          {/* <div className='customer-item' onClick={goCustomer}>
                                            <div><UserOutline /></div>
                                            <span>客服</span>
                                          </div> */}
                                          {/* <div className='customer-item'>
                                            <div><StarFill /></div>
                                            <span>收藏</span>
                                          </div> */}
  
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </List.Item>
                                ) : (
                                  <List.Item
                                    arrow={false}
                                    key={index}
                                    className="list-item list-item-mode"
                                  >
                                <div
                                  className='list-item-title'
                                >
                                  <div className="p-game-list-img">
                                    <span className='img-num'>{item?.images?.length}图</span>
                                      <Space wrap>
                                          <Image
                                            src={item?.images?.[0]}
                                            onClick={() => {
                                              setImgShow(true)
                                              ImageViewer.Multi.show({
                                                images: item?.images?.map(value => value?.originImage || value),
                                                onClose: () => {
                                                  setImgShow(false);
                                                }
                                              })
                                            }}
                                          />
                                      </Space>
                                      <div className="tips" onClick={() => goGameList(item)}>
                                          <div
                                            className="tip blue"
                                          >
                                            {item?.categoryName}
                                          </div>
                                          {
                                            item?.serverName && (
                                              <>
                                                <i></i>
                                                <div
                                                    className="tip"
                                                  >
                                                    {
                                                      item?.serverName
                                                    }
                                                  </div>
                                              </>
                                            )
                                          }
                                          
                                          {
                                            gameMode == 0 && (
                                              <>
                                                  <i></i>
                                                  <div
                                                    className="tip"
                                                  >
                                                    {filterTabList?.filter(v => v?.id === rootId)?.[0]?.name}
                                                  </div>
                                              </>
                                            )
                                          }
                                          
                                    </div>
                                    </div>
                                    <div className='game-detail-info' >
                                      <div className='game-info' onClick={() => goGameList(item)}>
                                          <div className="game-header-title">
                                            <div className='title'  >
                                            {item?.title}
                                            </div>
                                            <div className='item-price'>￥{(item?.price*1 || 0)?.toFixed(2)}</div>
                                          </div>
                                          <div className="game-tag-list">
                                            {
                                              gameMode == 0 && (item?.sellPoints || [])?.map(v => (
                                                <span>{v?.tagName}</span>
                                              ))
                                            }
                                            {
                                              gameMode == 1 && <span>{item?.sub_title}</span>
                                            }
                                          </div>
                                          
                                          
                                      </div>
                                      
                                      <div className="item-detail" onClick={() => goGameList(item)}>
                                          
                                          {
                                            buttonInfo?.name && 
                                            <Button
                                              shape='default'
                                              color='primary'
                                              onClick={() => {
                                                window.open(`${buttonInfo?.url}`);
                                              }}
                                            >
                                              {buttonInfo?.name}
                                            </Button>
                                          }
                                      </div>
                                      <div className="game-customer">
                                        <div className='tag-box'>
                                              
                                              <div className="tag-list">
                                                <div className="tag-item">
                                                  找回包赔
                                                </div>
                                                <div className="tag-item">
                                                  官方验号
                                                </div>
                                                <div className="tag-item">
                                                  安全保障
                                                </div>
                                              </div>
                                          </div>
                                        <div className='customer-item' onClick={goCustomer}>
                                          <div><UserOutline /></div>
                                          <span>客服</span>
                                        </div>
                                        {/* <div className='customer-item'>
                                          <div><StarFill /></div>
                                          <span>收藏</span>
                                        </div> */}

                                      </div>
                                    </div>
                                  </div>
                                  
                                </List.Item>
                                )
                              }
                              </>
                            )
                        })
                      }
                  </List>
                </PullToRefresh>
                <InfiniteScroll threshold={1} loadMore={loadMore} hasMore={hasMore} />
            </>
            ) : (
              <Empty description='暂无数据' />
            )
          }
      </div>
      {
        buttonInfo?.name1 && (
          <div className="de-row-footer"
            onClick={() => {
              window.open(`${buttonInfo?.url}`);
            }}
          >
                <div>
                  <UserContactOutline />{buttonInfo?.name1}
                </div>
          </div>
        )
      }
      <CoedMask lock={buttonInfo?.lock} buttonListServer={buttonInfo?.data?.button_list?.button_list_server} onCallback={callbackMask}/>
      
    </div>
  )
}
export default GameList