import axios from '@/utils/request';
// import fetch from 'fetch'; 


// 热门游戏列表
export function getNowGameList(params) {
  return axios.get('/Games/getNot', params);
}
export function getGameListData(params) {
  return axios.get('/Games/search', params);
}

// 游戏列表
export function getGameList(params) {
    return axios.get('/games', params);
}

// 筛选列表
export function getGameClassList(params) {
  return axios.get('/GoodsList/getClass', params);
}

export function getConditionsForSearch(params) {
  return axios.get('/GoodsList/ConditionsForSearch', params);
}


export function getSearchCondition(params) {
  return axios.get('/GoodsList/getSearchCondition', params);
}

//服务器搜索

export function getByCondition(params) {
  return axios.get('/GoodsList/getBycondition', params);
}

export function getGoodsListConditionsForSearch(params) {
  return axios.get('/getClientlist', params);
}


export function getGoodsList(params) {
  return axios.get('/GoodsList/getGoodsList', params);
}

export function getGameDetail(params) {
  return axios.get('/item/index', params);
}

export function getGameServerList(params) {
  return axios.get('/GoodsList/getServer', params);
}



export function getGameXunShowType(params) {
  return axios.get('/Config/index', params);
}
export function getDirection(params) {
  return axios.get('/direction', params);
}

export function getHotSearch(params) {
  return axios.get('/games/getHotSearch', params);
}

export function getGamesByList(params) {
  return axios.get('/games/getGamesByList', params);
}

export function getGamesAdvertise(params) {
  return axios.get('/Config/Advertise', params);
}

export function checkPswLock(params) {
  return axios.get('/index/isLock', params);
}


// 广告位

// export function getDirection(params) {
//   return axios.get('/direction', params);
// }