import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import 'lib-flexible';
import AppRouter from './router';
import store from './redux/store';
import './index.css';

const root = createRoot(document.getElementById("root"));
root.render(<Router>
  <Provider store={store}>
      <AppRouter />
  </Provider>
</Router>);